var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderInternalDetails" }, [
    _vm.detailInfo.orderInfo
      ? _c("div", [
          _c("div", { staticClass: "orderInternalDetails-title" }, [
            _c("div", { staticClass: "msg-left" }, [
              _c("p", { staticClass: "text" }, [_vm._v("供应商信息")]),
              _c("div", { staticClass: "content" }, [
                _vm._m(0),
                _c("div", { staticClass: "line" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.detailInfo.orderInfo.sellClientName)),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.detailInfo.orderInfo.sellUser))]),
                  _c("p", [_vm._v(_vm._s(_vm.detailInfo.orderInfo.sellTel))]),
                  _c(
                    "p",
                    {
                      staticClass: "textOverflow",
                      staticStyle: { width: "460px" },
                      attrs: { title: _vm.detailInfo.orderInfo.sellAddress },
                    },
                    [_vm._v(_vm._s(_vm.detailInfo.orderInfo.sellAddress))]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "msg-right" }, [
              _c("p", { staticClass: "text text-right" }, [_vm._v("买方信息")]),
              _c("div", { staticClass: "content content-right" }, [
                _vm._m(1),
                _c("div", { staticClass: "line" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.detailInfo.orderInfo.buyClientName)),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.detailInfo.orderInfo.buyUser))]),
                  _c("p", [_vm._v(_vm._s(_vm.detailInfo.orderInfo.buyTel))]),
                  _c(
                    "p",
                    {
                      staticClass: "textOverflow",
                      staticStyle: { width: "460px" },
                      attrs: { title: _vm.detailInfo.orderInfo.buyAddress },
                    },
                    [_vm._v(_vm._s(_vm.detailInfo.orderInfo.buyAddress))]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.$route.query.orderType !== "BI"
            ? _c("div", { staticClass: "orderInternalDetails-require" }, [
                _c("p", { staticClass: "text" }, [_vm._v("竞买要求")]),
                _c("p", {
                  staticClass: "main",
                  domProps: {
                    innerHTML: _vm._s(_vm.detailInfo.orderInfo.remark),
                  },
                }),
              ])
            : _vm._e(),
          _vm.$route.query.orderType === "BI"
            ? _c("div", { staticClass: "orderInternalDetails-require" }, [
                _c("p", { staticClass: "text" }, [_vm._v("竞标要求")]),
                _c("div", { staticClass: "bi-require" }, [
                  _c("div", [
                    _c("span", [_vm._v("计价要求:")]),
                    _c("p", {
                      staticClass: "main",
                      domProps: {
                        innerHTML: _vm._s(_vm.detailInfo.orderInfo.pricRequire),
                      },
                    }),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("质量要求:")]),
                    _c("p", {
                      staticClass: "main",
                      domProps: {
                        innerHTML: _vm._s(_vm.detailInfo.orderInfo.qualRequire),
                      },
                    }),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("交付要求:")]),
                    _c("p", {
                      staticClass: "main",
                      domProps: {
                        innerHTML: _vm._s(_vm.detailInfo.orderInfo.delRequire),
                      },
                    }),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("采购要求:")]),
                    _c("p", {
                      staticClass: "main",
                      domProps: {
                        innerHTML: _vm._s(_vm.detailInfo.orderInfo.bidRequire),
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "orderInternalDetails-information clearfix" },
            [
              _c(
                "div",
                { staticClass: "orderInternalDetails-information-header" },
                [
                  _c("p", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("商品信息"),
                  ]),
                  this.$route.query.orderType === "BI"
                    ? _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c("span", { staticStyle: { width: "90px" } }, [
                            _vm._v("商品查询"),
                          ]),
                          _c("el-input", {
                            staticStyle: { margin: "0 10px" },
                            attrs: { placeholder: "请输入商品名称查询" },
                            model: {
                              value: _vm.productName,
                              callback: function ($$v) {
                                _vm.productName = $$v
                              },
                              expression: "productName",
                            },
                          }),
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v("确定"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.tableData.length
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "orderInternalDetails-information-main",
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.tableData, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "productName",
                                  label: "商品名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "productType",
                                  label: "产品类型",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "specification",
                                  label: "规格型号",
                                },
                              }),
                              _vm.$route.query.orderType === "BI"
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      prop: "unitPrice",
                                      label: "单价(元)",
                                    },
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "number",
                                  label: "数量",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "unit",
                                  label: "单位",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        staticClass: "fr",
                        attrs: {
                          background: true,
                          "page-size": 5,
                          layout: "prev, pager, next ,jumper",
                          total: _vm.total,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        height: "300px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                    },
                    [_vm._m(2)]
                  ),
            ]
          ),
          _c("div", { staticClass: "orderInternalDetails-price" }, [
            _c("span", [_vm._v("应付总计：")]),
            _c("span", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.detailInfo.orderInfo.orderPrice)),
            ]),
            _c("span", [_vm._v("元")]),
          ]),
          _c(
            "div",
            { staticClass: "orderInternalDetails-btn" },
            [
              _vm.detailInfo.orderInfo.orderStatus === "ZFC" &&
              _vm.$route.query.customer === "sellder" &&
              _vm.$route.query.isHandleShow
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.receivePayment },
                    },
                    [_vm._v("确认收款")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line sell" }, [
      _c("p", [_vm._v("供应商：")]),
      _c("p", [_vm._v("联系人：")]),
      _c("p", { staticStyle: { "text-align": "right" } }, [_vm._v("电话：")]),
      _c("p", { staticStyle: { "text-align": "right" } }, [_vm._v("地址：")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line sell" }, [
      _c("p", { staticStyle: { "text-align": "right" } }, [_vm._v("买方：")]),
      _c("p", [_vm._v("联系人：")]),
      _c("p", { staticStyle: { "text-align": "right" } }, [_vm._v("电话：")]),
      _c("p", { staticStyle: { "text-align": "right" } }, [_vm._v("地址：")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "flex-direction": "column",
          "align-items": "center",
          "justify-content": "center",
        },
      },
      [
        _c("img", { attrs: { src: require("@/assets/empty.png") } }),
        _c("span", { staticStyle: { "margin-top": "20px" } }, [
          _vm._v("暂无搜索内容请重新搜索"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }