<template>
  <div class="orderInternalDetails">
    <div v-if="detailInfo.orderInfo">
      <div class="orderInternalDetails-title">
        <div class="msg-left">
          <p class="text">供应商信息</p>
          <div class="content">
            <div class="line sell">
              <p>供应商：</p>
              <p>联系人：</p>
              <p style="text-align: right">电话：</p>
              <p style="text-align: right">地址：</p>
            </div>
            <div class="line">
              <p>{{ detailInfo.orderInfo.sellClientName }}</p>
              <p>{{ detailInfo.orderInfo.sellUser }}</p>
              <p>{{ detailInfo.orderInfo.sellTel }}</p>
              <p :title="detailInfo.orderInfo.sellAddress" class="textOverflow" style="width:460px">{{ detailInfo.orderInfo.sellAddress }}</p>
            </div>
          </div>
        </div>
        <div class="msg-right">
          <p class="text text-right">买方信息</p>
          <div class="content content-right">
            <div class="line sell">
              <p style="text-align: right">买方：</p>
              <p>联系人：</p>
              <p style="text-align: right">电话：</p>
              <p style="text-align: right">地址：</p>
            </div>
            <div class="line">
              <p>{{ detailInfo.orderInfo.buyClientName }}</p>
              <p>{{ detailInfo.orderInfo.buyUser }}</p>
              <p>{{ detailInfo.orderInfo.buyTel }}</p>
              <p :title="detailInfo.orderInfo.buyAddress" class="textOverflow" style="width:460px">{{ detailInfo.orderInfo.buyAddress }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$route.query.orderType !== 'BI'" class="orderInternalDetails-require">
        <p class="text">竞买要求</p>
        <p class="main" v-html="detailInfo.orderInfo.remark"></p>
      </div>
      <div v-if="$route.query.orderType === 'BI'" class="orderInternalDetails-require ">
        <p class="text">竞标要求</p>
        <div class="bi-require">
          <div>
            <span>计价要求:</span>
            <p class="main" v-html="detailInfo.orderInfo.pricRequire"></p>
          </div>
          <div>
            <span>质量要求:</span>
            <p class="main" v-html="detailInfo.orderInfo.qualRequire"></p>
          </div>
          <div>
            <span>交付要求:</span>
            <p class="main" v-html="detailInfo.orderInfo.delRequire"></p>
          </div>
          <div>
            <span>采购要求:</span>
            <p class="main" v-html="detailInfo.orderInfo.bidRequire"></p>
          </div>
        </div>
      </div>
      <div class="orderInternalDetails-information clearfix">
        <div class="orderInternalDetails-information-header">
          <p style="font-size: 18px;">商品信息</p>
          <div v-if="this.$route.query.orderType === 'BI'" class="flex" style="align-items: center;">
            <span style="width: 90px;">商品查询</span>
            <el-input style=" margin: 0 10px" v-model="productName" placeholder="请输入商品名称查询"></el-input>
            <el-button type="primary">确定</el-button>
          </div>
        </div>
        <div v-if="tableData.length">
          <div class="orderInternalDetails-information-main">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column align="center" prop="productName" label="商品名称"> </el-table-column>
              <el-table-column align="center" prop="productType" label="产品类型"> </el-table-column>
              <el-table-column align="center" prop="specification" label="规格型号"> </el-table-column>
              <el-table-column v-if="$route.query.orderType === 'BI'" align="center" prop="unitPrice" label="单价(元)"> </el-table-column>
              <el-table-column align="center" prop="number" label="数量"> </el-table-column>
              <el-table-column align="center" prop="unit" label="单位"> </el-table-column>
            </el-table>
          </div>
          <el-pagination class="fr" :background="true" :page-size="5" layout="prev, pager, next ,jumper" :total="total" />
        </div>
        <div v-else style="height: 300px;display: flex;align-items: center;justify-content: center;">
          <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <img src="@/assets/empty.png" />
            <span style="margin-top:20px">暂无搜索内容请重新搜索</span>
          </div>
        </div>
      </div>
      <div class="orderInternalDetails-price">
        <span>应付总计：</span>
        <span class="number">{{ detailInfo.orderInfo.orderPrice }}</span>
        <span>元</span>
      </div>
      <div class="orderInternalDetails-btn">
        <el-button v-if="detailInfo.orderInfo.orderStatus === 'ZFC' && $route.query.customer === 'sellder' && $route.query.isHandleShow" class="btn" type="primary" @click="receivePayment"
          >确认收款</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {getTBidProductListData, oprOrderStatus} from '../../api';
export default {
  name: 'OrderInternalDetails',
  props: {
    detailInfo: {
      type: Object
    }
  },
  data() {
    return {
      btnText: '',
      productName: '',
      radio: '',
      options: [],
      deliveryStatus: '',
      tableData: [],
      total: 0
    };
  },
  mounted() {
    setTimeout(() => {
      console.log(this.detailInfo);
      let myObj = {};
      if (this.$route.query.orderType === 'BI') {
        this.getProductData();
      } else {
        // 竞买、竞卖没有单价字段
        myObj.productName = this.detailInfo.orderInfo.goods[0].productName;
        myObj.productType = this.detailInfo.orderInfo.goods[0].categoryName;
        myObj.specification = this.detailInfo.orderInfo.goods[0].productSpec;
        myObj.number = this.detailInfo.orderInfo.goods[0].productCount;
        myObj.unit = this.detailInfo.orderInfo.goods[0].unit;
        this.total = 0;
        this.tableData.push(myObj);
      }
    }, 500);
  },
  methods: {
    receivePayment() {
      this.$confirm('是否将订单修改为已收款', '收款确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmSubmit();
      });
    },
    confirmSubmit() {
      const req = {
        orderId: this.$route.query.orderid,
        status: 'SK'
      };
      oprOrderStatus(req).then(res => {
        if (res.errno === 0) {
          this.$notify.success({
            title: '成功',
            message: '提交成功'
          });
          this.$emit('upSuccess');
        }
        this.dialogVisible = false;
      });
    },
    async getProductData() {
      let res = await getTBidProductListData({
        orderId: this.$route.query.orderid,
        productName: this.productName
      });
      if (res.errno === 0) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.orderInternalDetails {
  padding: 45px;
  background-color: #fff;
  .text {
    font-size: 17px;
    font-weight: 600;
    color: #b4bdca;
    margin-bottom: 10px;
  }
  &-title {
    display: flex;
    padding-bottom: 25px;
    padding-left: 20px;
    // border-bottom: 1px dashed #bbb;

    .text-right {
      padding-left: 65px;
    }
    .msg-left {
      width: 45%;
    }
    .msg-right {
      width: 55%;
    }
    .content {
      padding-left: 55px;
      display: flex;
    }
    .content-right {
      border-left: 1px solid #ebebeb;
      padding-left: 120px;
    }
    .line {
      line-height: 30px;
    }
  }
  .bi-require {
    padding-left: 20px;
    line-height: 30px;
    span {
      font-size: 14px;
    }
  }
  &-require {
    border-top: 1px dashed #bbb;
    border-bottom: 1px dashed #bbb;
    padding: 45px 20px;
    .main {
      padding-left: 55px;
      line-height: 20px;
    }
  }
  &-information {
    padding: 35px 0;
    border-bottom: 1px dashed #bbb;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 25px;
      margin: -5px 0 0 65px;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-main {
      padding: 40px 0 55px 0;
    }
  }
  &-price {
    font-size: 20px;
    padding-top: 30px;
    text-align: right;

    .number {
      margin: 0 10px;
      color: #ff4400;
    }
  }
  &-btn {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 30px;
    }
  }
}
</style>
